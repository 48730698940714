import { twJoin } from '@pledge-earth/product-language';
import { useCallback, useRef, useState } from 'react';

import { DesktopMenu } from '../../components/NavigationMenu/DesktopMenu';
import { MobileMenu } from '../../components/NavigationMenu/MobileMenu';
import { TopBar } from '../../components/TopBar/TopBar';

export function MainLayout({ children }: { children: React.ReactNode }) {
  const [isMenuCollapsed, setIsMenuCollapsed] = useState<boolean>(false);
  const menuExpandButtonRef = useRef<HTMLButtonElement>(null);
  const menuCollapseButtonRef = useRef<HTMLButtonElement>(null);

  const toggleMenuCollapsed = useCallback(() => {
    if (isMenuCollapsed) {
      setIsMenuCollapsed(false);
      setTimeout(() => menuCollapseButtonRef.current?.focus(), 0);
    } else {
      setIsMenuCollapsed(true);
      setTimeout(() => menuExpandButtonRef.current?.focus(), 0);
    }
  }, [isMenuCollapsed]);

  return (
    <div
      id="MainLayout"
      className={twJoin(
        'group/layout grid min-h-full grid-rows-[3.5rem,1fr]',
        'lg:grid-cols-[15.75rem,1fr] lg:transition-[grid-template-columns] lg:data-[menu-collapsed]:grid-cols-[4.5rem,1fr]',
      )}
      data-menu-collapsed={isMenuCollapsed || undefined}
    >
      <header
        className={twJoin(
          'sticky top-0 z-10 border-b border-default bg-background-subdued',
          'lg:col-start-2 lg:bg-background-default',
        )}
      >
        <TopBar
          onMenuExpanded={toggleMenuCollapsed}
          menuExpandButtonRef={menuExpandButtonRef}
        >
          <MobileMenu />
        </TopBar>
      </header>

      <nav
        className={twJoin(
          'hidden',
          'lg:sticky lg:top-0 lg:row-span-2 lg:row-start-1 lg:block lg:h-dvh lg:overflow-y-scroll lg:border-r lg:border-default lg:bg-background-subdued lg:[scrollbar-width:none]',
        )}
      >
        <DesktopMenu
          onMenuCollapsed={toggleMenuCollapsed}
          menuCollapseButtonRef={menuCollapseButtonRef}
        />
      </nav>

      <main className="z-0 flex min-w-0 flex-col">
        <div className="z-0 w-full max-w-[79rem] grow self-center px-5 py-6 lg:p-8">
          {children}
        </div>
      </main>
    </div>
  );
}
