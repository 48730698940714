import { IconButton, MenuExpandIcon } from '@pledge-earth/product-language';
import type { ReactNode, RefObject } from 'react';
import { useIntl } from 'react-intl';

import { UserMenu } from './UserMenu/UserMenu';

export function TopBar({
  children,
  onMenuExpanded,
  menuExpandButtonRef,
}: {
  children?: ReactNode;
  onMenuExpanded: () => void;
  menuExpandButtonRef: RefObject<HTMLButtonElement | null>;
}) {
  const { formatMessage } = useIntl();

  return (
    <div className="flex h-full flex-row items-center justify-end gap-2 px-4 lg:px-6">
      <IconButton
        ref={menuExpandButtonRef}
        variant="subtle"
        label={formatMessage({ id: 'expand' })}
        className="mr-auto hidden shrink-0 lg:group-data-[menu-collapsed]/layout:inline-flex"
        onPress={onMenuExpanded}
      >
        <MenuExpandIcon />
      </IconButton>

      {children}

      <UserMenu />
    </div>
  );
}
