import {
  Avatar,
  IconButton,
  MenuCollapseIcon,
  Size,
  Text,
} from '@pledge-earth/product-language';
import type { RefObject } from 'react';
import { useIntl } from 'react-intl';

import { AvatarImage } from '../AvatarImage/AvatarImage';

import { NavigationMenu } from './NavigationMenu';

/** The left menu, only visible on lg screens and above */
export function DesktopMenu({
  onMenuCollapsed,
  menuCollapseButtonRef,
}: {
  onMenuCollapsed: () => void;
  menuCollapseButtonRef: RefObject<HTMLButtonElement | null>;
}) {
  const { formatMessage } = useIntl();

  return (
    <div className="flex h-full flex-col">
      {/* menu header */}

      <div className="hidden h-14 shrink-0 flex-row items-center gap-2 border-b border-default px-5 lg:flex">
        <Avatar className="shrink-0" variant="square">
          <AvatarImage src="/images/pledge-icon.png" fallback="P" />
        </Avatar>

        <Text
          size={Size.Loose}
          elementType="div"
          className="truncate font-medium transition-opacity group-data-[menu-collapsed]/layout:opacity-0"
        >
          Pledge
        </Text>

        <IconButton
          ref={menuCollapseButtonRef}
          variant="subtle"
          label={formatMessage({ id: 'collapse' })}
          className="ml-auto shrink-0 group-data-[menu-collapsed]/layout:hidden"
          onPress={onMenuCollapsed}
        >
          <MenuCollapseIcon />
        </IconButton>
      </div>

      {/* menu body */}
      <NavigationMenu className="flex-1 p-4" />
    </div>
  );
}
