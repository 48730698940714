import {
  Button,
  CategoryFilledIcon,
  DashboardIcon,
  DialogModal,
  FileSpreadsheetIcon,
  ImageIcon,
  ImagesIcon,
  PeopleIcon,
  PlusCircleFilledIcon,
  ProjectCertificateIcon,
  ProjectDeveloperIcon,
  ProjectOwnerIcon,
  ToolboxIcon,
  twMerge,
} from '@pledge-earth/product-language';
import { DialogTrigger } from 'react-aria-components';
import { FormattedMessage } from 'react-intl';

import { AddEntity as AddNew } from '../AddEntity/AddEntity';
import {
  NavItem,
  NavItemLink,
  NavMenu,
  NavSection,
  NavSectionHeading,
} from '../Nav/Nav';
import { DotIcon } from '../SideBarSVG/DotIcon';

export function NavigationMenu({ className }: { className?: string }) {
  return (
    <div className={twMerge('flex flex-col gap-4', className)}>
      {/* menu body top section */}
      <div className="flex flex-1 flex-col gap-3">
        <NavSection>
          <NavMenu>
            <NavItem>
              <NavItemLink to="/dashboard">
                <DashboardIcon />
                <FormattedMessage id="leftMenu.home" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/clients">
                <PeopleIcon />
                <FormattedMessage id="leftMenu.clients" />
              </NavItemLink>
            </NavItem>
          </NavMenu>
        </NavSection>

        <NavSection>
          <NavSectionHeading className="group-data-[menu-collapsed]/layout:opacity-0">
            <FormattedMessage id="leftMenu.offsetting" />
          </NavSectionHeading>
          <NavMenu>
            <NavItem>
              <NavItemLink to="/orders">
                <DotIcon />
                <FormattedMessage id="leftMenu.orders" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/offsetting/portfolios">
                <ImagesIcon />
                <FormattedMessage id="leftMenu.portfolios" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/offsetting/projects">
                <ImageIcon />
                <FormattedMessage id="leftMenu.projects" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/offsetting/project-developers">
                <ProjectDeveloperIcon />
                <FormattedMessage id="leftMenu.projectDevelopers" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/offsetting/project-owners">
                <ProjectOwnerIcon />
                <FormattedMessage id="leftMenu.projectOwners" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/offsetting/project-types">
                <CategoryFilledIcon />
                <FormattedMessage id="leftMenu.projectTypes" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/offsetting/project-certifications">
                <ProjectCertificateIcon />
                <FormattedMessage id="leftMenu.projectCertifications" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/offsetting/inventory">
                <DotIcon />
                <FormattedMessage id="leftMenu.inventory" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/offsetting/inventoryReconciliation">
                <DotIcon />
                <FormattedMessage id="leftMenu.inventoryReconciliation" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/offsetting/retirements">
                <DotIcon />
                <FormattedMessage id="leftMenu.retirements" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/offsetting/retirements/pending">
                <DotIcon />
                <FormattedMessage id="leftMenu.retirements_pending" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/offsetting/registries">
                <FileSpreadsheetIcon />
                <FormattedMessage id="leftMenu.registries" />
              </NavItemLink>
            </NavItem>
          </NavMenu>
        </NavSection>

        <NavSection>
          <NavSectionHeading className="group-data-[menu-collapsed]/layout:opacity-0">
            <FormattedMessage id="leftMenu.data-collection" />
          </NavSectionHeading>
          <NavMenu>
            <NavItem>
              <NavItemLink to="/data-collection/configure-supplier-entitlements">
                <DotIcon />
                <FormattedMessage id="leftMenu.data-collection.configure-supplier-entitlements" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/data-collection/link-import">
                <DotIcon />
                <FormattedMessage id="leftMenu.data-collection.link-import" />
              </NavItemLink>
            </NavItem>
            <NavItem>
              <NavItemLink to="/data-collection/submit-request-data">
                <DotIcon />
                <FormattedMessage id="leftMenu.data-collection.submit-request-data" />
              </NavItemLink>
            </NavItem>
          </NavMenu>
        </NavSection>
        <NavSection>
          <NavSectionHeading className="group-data-[menu-collapsed]/layout:opacity-0">
            <FormattedMessage id="leftMenu.tools" />
          </NavSectionHeading>
          <NavMenu>
            <NavItem>
              <NavItemLink to="/tools">
                <ToolboxIcon />
                <FormattedMessage id="leftMenu.tools" />
              </NavItemLink>
            </NavItem>
          </NavMenu>
        </NavSection>
      </div>

      {/* menu body bottom section */}
      <div>
        <DialogTrigger>
          <Button variant="subtle">
            <PlusCircleFilledIcon />
            <span className="group-data-[menu-collapsed]/layout:opacity-0">
              Add new
            </span>
          </Button>

          <DialogModal
            shouldCloseOnInteractOutside={(el) =>
              el.closest('.ant-select-dropdown') == null
            }
            size="large"
          >
            {({ state }) => <AddNew closeModal={state.close} />}
          </DialogModal>
        </DialogTrigger>
      </div>
    </div>
  );
}
