import {
  ContextualHelp,
  Form,
  FormSection,
  FormSectionHeader,
  Heading,
  OptionListItem,
  Size,
} from '@pledge-earth/product-language';
import { Spin } from '@pledge-earth/web-components';
import type { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormErrors } from '../../../components/ReactHookForm/FormErrors';
import { TagFieldControlled } from '../../../components/ReactHookForm/TagFieldControlled';
import { TextFieldControlled } from '../../../components/ReactHookForm/TextFieldControlled';
import type { Client } from '../../../services/graphql/generated';
import type { FormData } from '../PortfolioEdit';

export function PortfolioEditSettings({
  formId,
  clientList,
  loadingClients,
  formMethods,
}: {
  formId: string;
  clientList: Pick<Client, 'id' | 'legal_name'>[] | undefined;
  loadingClients: boolean;
  formMethods: UseFormReturn<FormData>;
}) {
  const { formatMessage } = useIntl();
  const { control, formState } = formMethods;

  return (
    <Spin spinning={loadingClients}>
      <Form id={formId} noValidate={true} aria-label="Edit portfolio">
        <FormErrors formState={formState} />

        <FormSection>
          <FormSectionHeader>
            <Heading>
              <FormattedMessage id="portfolio.edit.entitlements" />
            </Heading>
          </FormSectionHeader>

          <TagFieldControlled
            control={control}
            name="portfolio_draft.acl"
            label={formatMessage({
              id: 'portfolio.edit.acl',
            })}
            size={Size.Loose}
            items={clientList ?? []}
            menuTrigger="focus"
            renderTag={(key) => {
              const option = clientList?.find((client) => client.id === key);

              return option?.legal_name || key;
            }}
          >
            {(item) => (
              <OptionListItem id={item.id}>
                {item.legal_name || item.id}
              </OptionListItem>
            )}
          </TagFieldControlled>
        </FormSection>

        <FormSection>
          <FormSectionHeader>
            <Heading>
              <FormattedMessage id="portfolio.edit.accounting" />
            </Heading>
          </FormSectionHeader>

          <TextFieldControlled
            control={control}
            name="portfolio_draft.details.accounting_code"
            label={
              <>
                <FormattedMessage id="portfolio.edit.accounting_code" />
                <ContextualHelp>
                  <FormattedMessage id="portfolio.edit.accounting_code.tooltip" />
                </ContextualHelp>
              </>
            }
            size={Size.Loose}
            placeholder={formatMessage({
              id: 'portfolio.edit.accounting_code.placeholder',
            })}
          />

          <TextFieldControlled
            label={formatMessage({
              id: 'portfolio.edit.max_commission',
            })}
            size={Size.Loose}
            control={control}
            disabled={true}
            suffix="%"
            name="max_commission_percentage"
            rules={{
              required: formatMessage({
                id: 'portfolio.edit.max_commission.required',
              }),
            }}
          />
        </FormSection>
      </Form>
    </Spin>
  );
}
