import type { LinkProps as PledgeLinkProps } from '@pledge-earth/product-language';
import {
  ExternalMinorIcon,
  Link as PledgeLink,
} from '@pledge-earth/product-language';

type ExternalLinkProps = PledgeLinkProps<'a'> & {
  noExternalIcon?: boolean;
};

export function ExternalLink({
  children,
  noExternalIcon = false,
  ...props
}: ExternalLinkProps) {
  return (
    <PledgeLink
      elementType="a"
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {children}{' '}
      {!noExternalIcon && (
        <ExternalMinorIcon className="ml-1 inline align-middle" />
      )}
    </PledgeLink>
  );
}
