import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { TypedDocumentNode } from '@apollo/client';

import type { TagFieldAutocompleteProps } from '../Input/TagFieldAutocomplete/TagFieldAutocomplete';
import { TagFieldAutocomplete } from '../Input/TagFieldAutocomplete/TagFieldAutocomplete';

type Props<
  TItem extends object,
  TQuery extends TypedDocumentNode<any, any>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  Omit<
    TagFieldAutocompleteProps<TItem, TQuery>,
    | 'ref'
    | 'name'
    | 'selectedKeys'
    | 'onSelectionChange'
    | 'onBlur'
    | 'validationBehavior'
    | 'isInvalid'
    | 'errorMessage'
    | 'isDisabled'
  >;

/** a TagFieldAutocomplete wrapped in a controller */
export function TagFieldAutocompleteControlled<
  TItem extends object,
  TQuery extends TypedDocumentNode<any, any>,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  defaultValue,
  rules,
  shouldUnregister,
  disabled,
  ...rest
}: Props<TItem, TQuery, TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      disabled={disabled}
      render={({
        field: { name, value, onChange, onBlur, ref, disabled },
        fieldState: { invalid, error },
      }) => (
        <TagFieldAutocomplete
          ref={ref}
          name={name}
          selectedKeys={new Set(value)}
          onSelectionChange={(value) => onChange(Array.from(value))}
          onBlur={onBlur}
          isDisabled={disabled}
          // Let React Hook Form handle validation instead of the browser.
          validationBehavior="aria"
          isInvalid={invalid}
          errorMessage={error?.message}
          // Forward remaining props to TagFieldAutocomplete.
          {...rest}
        />
      )}
    />
  );
}
