import pick from 'lodash/pick';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { useCallback, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Spin } from '@pledge-earth/web-components';
import {
  Button,
  Heading,
  OptionListItem,
  Size,
  Dialog,
  DialogHeader,
  DialogTitle,
  OverlayCloseButton,
  DialogBody,
  DialogFooter,
  Form,
  FormSection,
  Checkbox,
  FormFieldGroup,
  FormSectionHeader,
} from '@pledge-earth/product-language';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import type {
  UpsertProjectMutationVariables,
  GetProjectQuery,
  S3Object,
} from '../../../services/graphql/generated';
import {
  SustainableDevelopmentGoalsEnum,
  GetCountriesDocument,
  GetRegistriesDocument,
  GetProjectDevelopersNameIdDocument,
  UpsertProjectDocument,
  GetProjectTypesDocument,
  GetProjectOwnersDocument,
  GetProjectCertificationsDocument,
  S3FolderEnum,
  GetProjectDocument,
} from '../../../services/graphql/generated';
import { EntityAdded } from '../EntityAdded/EntityAdded';
import { TextFieldControlled } from '../../ReactHookForm/TextFieldControlled';
import { SelectFieldControlled } from '../../ReactHookForm/SelectFieldControlled';
import { defaultUseFormProps } from '../../ReactHookForm/defaultUseFormProps';
import { CheckboxGroupControlled } from '../../ReactHookForm/CheckboxGroupControlled';
import { ImageUploadControlled } from '../../ReactHookForm/ImageUploadControlled';
import { FormErrors } from '../../ReactHookForm/FormErrors';
import { useRootFormError } from '../../ReactHookForm/useRootFormError';
import { mapSdgsToReadableSdgs } from '../../../pages/projects/details/sdgs/sdgs';
import { TagFieldAutocompleteControlled } from '../../ReactHookForm/TagFieldAutocompleteControlled';
import { TagFieldControlled } from '../../ReactHookForm/TagFieldControlled';

interface FormData {
  name: string;
  description: string;
  website: string;
  location: string;
  projectTypeId: string;
  mechanism: ('removal' | 'avoidance')[];
  countryId: string;
  developerId: string;
  ownerId: string;
  registryId: string;
  registryProjectUrl: string;
  registryProjectId: string;
  contact: string;
  latitude: string;
  longitude: string;
  projectCertificationIds: string[];
  sdgs: SustainableDevelopmentGoalsEnum[];
  carbonPlanId: string;
  logo: S3Object;
}

function parseProjectIntoFormValues(
  project?: GetProjectQuery['project'],
): Partial<FormData> | undefined {
  if (!project) {
    return undefined;
  }

  return {
    ...omitBy(
      pick(project, [
        'id',
        'description',
        'name',
        'sdgs',
        'registry_project_url',
        'registry_project_id',
        'contact',
        'website',
        'media',
        'location',
      ]),
      isNil,
    ),
    projectTypeId: project.project_type.id,
    registryId: project.registry?.id,
    ownerId: project.owner?.id,
    developerId: project.developer?.id,
    countryId: project.country.id,
    projectCertificationIds: project.certifications?.map(
      (certification) => certification.id,
    ),
    latitude: project.coordinates?.coordinates[0]?.toString(),
    longitude: project.coordinates?.coordinates[1]?.toString(),
    mechanism: _.compact([
      project.removal ? 'removal' : undefined,
      project.avoidance ? 'avoidance' : undefined,
    ]),
  };
}

export function AddProject({
  closeModal,
  projectDetails,
}: {
  closeModal: () => void;
  projectDetails?: GetProjectQuery['project'];
}) {
  const navigate = useNavigate();

  const [createdProjectId, setCreatedProjectId] = useState<string>();
  const { formatMessage } = useIntl();
  const [
    upsertProjectMutation,
    {
      loading: upsertProjectLoading,
      data: upsertProjectSuccess,
      error: upsertProjectFailure,
    },
  ] = useMutation(UpsertProjectDocument, {
    refetchQueries: [GetProjectDocument],
  });

  const { data: dataCountries, loading: loadingCountries } =
    useQuery(GetCountriesDocument);
  const { data: dataRegistries, loading: loadingRegistries } = useQuery(
    GetRegistriesDocument,
  );
  const { data: dataProjectDevelopers, loading: loadingProjectDevelopers } =
    useQuery(GetProjectDevelopersNameIdDocument);
  const { data: dataProjectTypes, loading: loadingProjectTypes } = useQuery(
    GetProjectTypesDocument,
  );
  const { data: dataProjectOwners, loading: loadingProjectOwners } = useQuery(
    GetProjectOwnersDocument,
  );

  const isLoadingDropdowns =
    loadingCountries ||
    loadingRegistries ||
    loadingProjectDevelopers ||
    loadingProjectTypes ||
    loadingProjectOwners;

  const formId = useId();
  const { control, handleSubmit, formState } = useForm<FormData>({
    ...defaultUseFormProps,
    errors: useRootFormError(upsertProjectFailure),
    defaultValues: parseProjectIntoFormValues(projectDetails),
  });

  const redirectToProjectDetailsPage = useCallback(() => {
    if (createdProjectId) {
      navigate(`/offsetting/projects/${createdProjectId}`);
    }
    closeModal();
  }, [createdProjectId, closeModal, navigate]);

  const submitAddProject = useCallback(
    async (inputData: FormData) => {
      const payload: UpsertProjectMutationVariables['data'] = {
        id: projectDetails?.id ?? undefined,
        name: inputData.name,
        description: inputData.description,
        website: inputData.website,
        project_type_id: inputData.projectTypeId,
        removal: inputData?.mechanism?.includes('removal') ?? false,
        avoidance: inputData?.mechanism?.includes('avoidance') ?? false,
        country_id: inputData.countryId,
        developer_id: inputData.developerId,
        owner_id: inputData.ownerId,
        registry_id: inputData.registryId,
        registry_project_id: inputData.registryProjectId,
        registry_project_url: inputData.registryProjectUrl,
        project_certification_ids: inputData.projectCertificationIds ?? [],
        sdgs: inputData.sdgs ?? [],
        contact: inputData.contact,
        location: inputData.location,
        coordinates: {
          coordinates: [
            parseFloat(inputData.longitude),
            parseFloat(inputData.latitude),
          ],
        },
      };
      payload.carbon_plan_id = inputData.carbonPlanId
        ?.split('?id=')
        ?.reverse()[0];
      payload.media = inputData.logo ? [inputData.logo] : null;
      const result = await upsertProjectMutation({
        variables: {
          data: payload,
        },
      });
      const projectId = result.data?.upsert_project.public_id;
      setCreatedProjectId(projectId);
    },
    [upsertProjectMutation, projectDetails],
  );

  const title = projectDetails
    ? formatMessage({ id: 'edit-project.title' })
    : formatMessage({ id: 'add-project.title' });

  const sharedFieldProps = {
    control,
    size: Size.Loose,
  };

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {upsertProjectSuccess ? (
          <EntityAdded
            closeModal={closeModal}
            entityAddedTitle={formatMessage({
              id: 'add-project.project_saved',
            })}
            actionButtonCTAText={formatMessage({
              id: 'add-project.view-project',
            })}
            actionButtonCTA={redirectToProjectDetailsPage}
          />
        ) : (
          <Spin spinning={isLoadingDropdowns}>
            <Form
              id={formId}
              aria-labelledby={title}
              onSubmit={handleSubmit(submitAddProject)}
              noValidate={true}
            >
              <FormErrors formState={formState} />

              <FormSection>
                <TextFieldControlled
                  {...sharedFieldProps}
                  name="name"
                  rules={{
                    required: formatMessage({
                      id: 'add-project.name.message.required',
                    }),
                  }}
                  label={formatMessage({ id: 'add-project.name' })}
                />

                <SelectFieldControlled
                  {...sharedFieldProps}
                  label={formatMessage({
                    id: 'add-project.project_type',
                  })}
                  items={dataProjectTypes?.project_types}
                  name="projectTypeId"
                  rules={{
                    required: formatMessage({
                      id: 'add-project.project_type.message.required',
                    }),
                  }}
                >
                  {(project) => (
                    <OptionListItem id={project.id}>
                      {project.name}
                    </OptionListItem>
                  )}
                </SelectFieldControlled>

                <TextFieldControlled
                  {...sharedFieldProps}
                  name="description"
                  rules={{
                    required: formatMessage({
                      id: 'add-project.description.message.required',
                    }),
                  }}
                  label={formatMessage({ id: 'add-project.description' })}
                />

                <CheckboxGroupControlled
                  control={control}
                  name="mechanism"
                  label={formatMessage({
                    id: 'add-project.mechanism',
                  })}
                >
                  <Checkbox value="removal">
                    <FormattedMessage id="add-project.removal" />
                  </Checkbox>
                  <Checkbox value="avoidance">
                    <FormattedMessage id="add-project.avoidance" />
                  </Checkbox>
                </CheckboxGroupControlled>

                <SelectFieldControlled
                  {...sharedFieldProps}
                  name="developerId"
                  label={formatMessage({
                    id: 'add-project.developer',
                  })}
                  items={dataProjectDevelopers?.project_developers}
                  rules={{
                    required: formatMessage({
                      id: 'add-project.developer.message.required',
                    }),
                  }}
                >
                  {(developer) => (
                    <OptionListItem id={developer.id}>
                      {developer.name}
                    </OptionListItem>
                  )}
                </SelectFieldControlled>

                <SelectFieldControlled
                  {...sharedFieldProps}
                  label={formatMessage({
                    id: 'add-project.owner',
                  })}
                  items={dataProjectOwners?.project_owners}
                  name="ownerId"
                  rules={{
                    required: formatMessage({
                      id: 'add-project.owner.message.required',
                    }),
                  }}
                >
                  {(owner) => (
                    <OptionListItem id={owner.id}>{owner.name}</OptionListItem>
                  )}
                </SelectFieldControlled>

                <SelectFieldControlled
                  {...sharedFieldProps}
                  label={formatMessage({
                    id: 'add-project.registry',
                  })}
                  items={dataRegistries?.registries}
                  name="registryId"
                  rules={{
                    required: formatMessage({
                      id: 'add-project.registry.message.required',
                    }),
                  }}
                >
                  {(registry) => (
                    <OptionListItem id={registry.id}>
                      {registry.name}
                    </OptionListItem>
                  )}
                </SelectFieldControlled>
              </FormSection>

              <FormSection>
                <FormSectionHeader>
                  <Heading>
                    <FormattedMessage id="add-project.additional-information" />
                  </Heading>
                </FormSectionHeader>

                <TagFieldAutocompleteControlled
                  control={control}
                  size={Size.Loose}
                  name="projectCertificationIds"
                  label={formatMessage({
                    id: 'add-project.project_certifications',
                  })}
                  queryDocument={GetProjectCertificationsDocument}
                  queryVariables={{}}
                  selectItems={(data) => data.project_certifications}
                >
                  {(certification) => (
                    <OptionListItem id={certification.id}>
                      {certification.name}
                    </OptionListItem>
                  )}
                </TagFieldAutocompleteControlled>
                <TagFieldControlled
                  control={control}
                  size={Size.Loose}
                  name="sdgs"
                  menuTrigger="focus"
                  label={formatMessage({
                    id: 'add-project.sdgs',
                  })}
                  items={Object.entries(SustainableDevelopmentGoalsEnum)}
                  renderTag={(value) =>
                    mapSdgsToReadableSdgs(
                      value as SustainableDevelopmentGoalsEnum,
                    )
                  }
                >
                  {([, value]) => (
                    <OptionListItem id={value}>
                      {mapSdgsToReadableSdgs(value)}
                    </OptionListItem>
                  )}
                </TagFieldControlled>
                <TextFieldControlled
                  {...sharedFieldProps}
                  name="carbonPlanId"
                  label={formatMessage({
                    id: 'add-project.carbon-plan-id',
                  })}
                  description={
                    <div>
                      To find the carbon plan ID, go to the&nbsp;
                      <a
                        href="https://carbonplan.org/research/cdr-database"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        CDR database
                      </a>
                      , find your project and click share and then paste the url
                      above
                    </div>
                  }
                />
                <TextFieldControlled
                  {...sharedFieldProps}
                  name="registryProjectId"
                  label={formatMessage({
                    id: 'add-project.registry-project-id',
                  })}
                />
                <TextFieldControlled
                  {...sharedFieldProps}
                  name="registryProjectUrl"
                  label={formatMessage({
                    id: 'add-project.registry-project-url',
                  })}
                />
                <TextFieldControlled
                  {...sharedFieldProps}
                  label={formatMessage({
                    id: 'add-project.website',
                  })}
                  name="website"
                  rules={{
                    required: formatMessage({
                      id: 'add-project.website.message.required',
                    }),
                  }}
                />
                <TextFieldControlled
                  {...sharedFieldProps}
                  name="contact"
                  label={formatMessage({
                    id: 'add-project.contact',
                  })}
                />
              </FormSection>

              <FormSection>
                <FormSectionHeader>
                  <Heading>
                    <FormattedMessage id="add-project.whereabouts.title" />
                  </Heading>
                </FormSectionHeader>

                <SelectFieldControlled
                  {...sharedFieldProps}
                  label={formatMessage({
                    id: 'add-project.country',
                  })}
                  items={dataCountries?.countries}
                  name="countryId"
                  rules={{
                    required: formatMessage({
                      id: 'add-project.country.message.required',
                    }),
                  }}
                >
                  {(country) => (
                    <OptionListItem id={country.id}>
                      {country.code} - {country.name}
                    </OptionListItem>
                  )}
                </SelectFieldControlled>

                <TextFieldControlled
                  {...sharedFieldProps}
                  label={formatMessage({
                    id: 'add-project.location',
                  })}
                  placeholder={formatMessage({
                    id: 'add-project.location.placeholder',
                  })}
                  name="location"
                  rules={{
                    required: formatMessage({
                      id: 'add-project.location.message.required',
                    }),
                  }}
                />

                <FormFieldGroup layout="horizontal">
                  <TextFieldControlled
                    {...sharedFieldProps}
                    label={formatMessage({
                      id: 'add-project.latitude',
                    })}
                    type="number"
                    name="latitude"
                    rules={{
                      required: formatMessage({
                        id: 'add-project.latitude.message.required',
                      }),
                    }}
                  />

                  <TextFieldControlled
                    {...sharedFieldProps}
                    label={formatMessage({
                      id: 'add-project.longitude',
                    })}
                    type="number"
                    name="longitude"
                    rules={{
                      required: formatMessage({
                        id: 'add-project.longitude.message.required',
                      }),
                    }}
                  />
                </FormFieldGroup>
              </FormSection>

              <FormSection>
                <FormSectionHeader>
                  <Heading>
                    <FormattedMessage id="add-project.media" />
                  </Heading>
                </FormSectionHeader>

                <ImageUploadControlled
                  control={control}
                  name="logo"
                  title={formatMessage({
                    id: 'add-project.upload_logo',
                  })}
                  folder={S3FolderEnum.ProjectDeveloper}
                />
              </FormSection>
            </Form>
          </Spin>
        )}
      </DialogBody>

      {!upsertProjectSuccess && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="add-project.cancel_button" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={upsertProjectLoading}
          >
            <FormattedMessage id="add-project.add_button" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
