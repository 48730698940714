import type { TagFieldProps } from '@pledge-earth/product-language';
import { TagField } from '@pledge-earth/product-language';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';

type Props<
  T extends object,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Omit<ControllerProps<TFieldValues, TName>, 'render'> &
  Omit<
    TagFieldProps<T>,
    | 'ref'
    | 'name'
    | 'selectedKeys'
    | 'onSelectionChange'
    | 'onBlur'
    | 'validationBehavior'
    | 'isInvalid'
    | 'errorMessage'
    | 'isDisabled'
  >;

/** a TagField wrapped in a controller */
export function TagFieldControlled<
  T extends object,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  defaultValue,
  rules,
  shouldUnregister,
  disabled,
  ...rest
}: Props<T, TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      shouldUnregister={shouldUnregister}
      disabled={disabled}
      render={({
        field: { name, value, onChange, onBlur, ref, disabled },
        fieldState: { invalid, error },
      }) => (
        <TagField
          ref={ref}
          name={name}
          selectedKeys={new Set(value)}
          onSelectionChange={(value) => onChange(Array.from(value))}
          onBlur={onBlur}
          isDisabled={disabled}
          // Let React Hook Form handle validation instead of the browser.
          validationBehavior="aria"
          isInvalid={invalid}
          errorMessage={error?.message}
          // Forward remaining props to TagField.
          {...rest}
        />
      )}
    />
  );
}
