import { useLazyQuery, type TypedDocumentNode } from '@apollo/client';
import type { TagFieldProps } from '@pledge-earth/product-language';
import { TagField } from '@pledge-earth/product-language';
import { debounce } from 'lodash';
import { useMemo, type ForwardedRef } from 'react';

import { genericForwardRef } from '../../../utils/genericForwardRef';

type InferQueryTypes<T> =
  T extends TypedDocumentNode<infer TResult, infer TVariables>
    ? { result: TResult; variables: TVariables }
    : never;

export type TagFieldAutocompleteProps<
  TItem extends object,
  TQuery extends TypedDocumentNode<any, any>,
> = {
  queryDocument: TQuery;
  queryVariables: Omit<InferQueryTypes<TQuery>['variables'], 'value'>;
  selectItems: (data: InferQueryTypes<TQuery>['result']) => TItem[];
} & Omit<
  TagFieldProps<TItem>,
  | 'items'
  | 'defaultItems'
  | 'allowsEmptyCollection'
  | 'menuTrigger'
  | 'isLoading'
>;

/**
 * TagField component with support for lazy-loading options from a provided query.
 */
export const TagFieldAutocomplete = /* #__PURE__ */ genericForwardRef(
  // eslint-disable-next-line prefer-arrow-callback
  function TagFieldAutocomplete<
    TItem extends object,
    TQuery extends TypedDocumentNode<any, any>,
  >(
    {
      queryDocument,
      queryVariables,
      selectItems,
      ...tagFieldProps
    }: TagFieldAutocompleteProps<TItem, TQuery>,
    forwardedRef: ForwardedRef<HTMLInputElement>,
  ) {
    const [getValues, { loading, data, previousData, called }] =
      useLazyQuery(queryDocument);

    const debouncedGetValues = useMemo(
      () => debounce(getValues, 300),
      [getValues],
    );

    const items = useMemo(
      () => (data || previousData ? selectItems(data || previousData) : []),
      [data, previousData, selectItems],
    );

    return (
      <TagField
        {...tagFieldProps}
        ref={forwardedRef}
        defaultItems={items}
        onInputChange={(filterText) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          debouncedGetValues({
            variables: { ...queryVariables, value: filterText },
          });
          tagFieldProps.onInputChange?.(filterText);
        }}
        onFocus={(e) => {
          if (!called) {
            // Initialise the list of items when the input is focused for the first time
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            getValues({
              variables: { ...queryVariables, value: e.currentTarget.value },
            });
          }
          tagFieldProps.onFocus?.(e);
        }}
        allowsEmptyCollection={true}
        menuTrigger="focus"
        isLoading={loading}
      />
    );
  },
);
