import { LoadingIcon } from '@pledge-earth/product-language';
import { Spin } from '@pledge-earth/web-components';
import * as Sentry from '@sentry/react';
import type { ComponentType } from 'react';
import { Suspense, lazy } from 'react';
import type { UIMatch } from 'react-router-dom';
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { AuthLayout } from './layouts/Auth/AuthLayout';
import { BaseLayout } from './layouts/BaseLayout';
import { MainLayout } from './layouts/Main/MainLayout';
import { getIntl } from './locales/intl';

function lazyComponent<TComponent extends ComponentType<any>, TModule>(
  module: Promise<TModule>,
  componentSelector: (module: TModule) => TComponent,
) {
  return lazy(() =>
    module.then((module) => ({ default: componentSelector(module) })),
  );
}

const Dashboard = lazyComponent(
  import('./pages/dashboard/Dashboard'),
  (module) => module.Dashboard,
);

const Clients = lazyComponent(
  import('./pages/clients/Clients'),
  (module) => module.Clients,
);

const ClientsAdd = lazyComponent(
  import('./pages/clients/add/NewClient'),
  (module) => module.NewClient,
);

const Client = lazyComponent(
  import('./pages/clients/client/Client'),
  (module) => module.Client,
);

const ClientIntegrationEdit = lazyComponent(
  import('./pages/clients/client/integration/edit'),
  (module) => module.ClientIntegrationEdit,
);

const Portfolios = lazyComponent(
  import('./pages/portfolios/PortfoliosTable'),
  (module) => module.PortfoliosTable,
);
const PortfolioDetails = lazyComponent(
  import('./pages/portfolios/PortfolioDetails'),
  (module) => module.PortfolioDetails,
);
const ProjectDevelopers = lazyComponent(
  import('./pages/projectDevelopers/projectDevelopersTable'),
  (module) => module.ProjectDevelopersTable,
);
const ProjectDevelopersDetails = lazyComponent(
  import('./pages/projectDevelopers/details/details'),
  (module) => module.ProjectDeveloperDetails,
);
const Projects = lazyComponent(
  import('./pages/projects/projectsTable'),
  (module) => module.ProjectsTable,
);
const ProjectDetails = lazyComponent(
  import('./pages/projects/details/details'),
  (module) => module.ProjectDetails,
);
const ProjectOwners = lazyComponent(
  import('./pages/projectOwners/ProjectOwnersTable'),
  (module) => module.ProjectOwnersTable,
);
const ProjectOwnerDetails = lazyComponent(
  import('./pages/projectOwners/ProjectOwnerDetails'),
  (module) => module.ProjectOwnerDetails,
);
const ProjectTypes = lazyComponent(
  import('./pages/projectTypes/ProjectTypesTable'),
  (module) => module.ProjectTypesTable,
);
const ProjectTypeDetails = lazyComponent(
  import('./pages/projectTypes/ProjectTypeDetails'),
  (module) => module.ProjectTypeDetails,
);
const ProjectCertifications = lazyComponent(
  import('./pages/projectCertifications/ProjectCertificationsTable'),
  (module) => module.ProjectCertificationsTable,
);
const ProjectCertificationDetails = lazyComponent(
  import('./pages/projectCertifications/ProjectCertificationDetails'),
  (module) => module.ProjectCertificationDetails,
);
const Inventory = lazyComponent(
  import('./pages/inventory/inventoryTable'),
  (module) => module.InventoryTable,
);
const InventoryAdd = lazyComponent(
  import('./pages/inventory/add/add'),
  (module) => module.AddInventory,
);
const InventoryRemove = lazyComponent(
  import('./pages/inventory/remove/remove'),
  (module) => module.RemoveInventory,
);
const InventoryReconciliation = lazyComponent(
  import('./pages/inventoryReconciliation/inventoryReconciliationTable'),
  (module) => module.InventoryReconciliationTable,
);
const RetirementsConfirmedTable = lazyComponent(
  import('./pages/retirements/retirementsConfirmedTable'),
  (module) => module.RetirementsConfirmedTable,
);
const RetirementsRequestedTable = lazyComponent(
  import('./pages/retirements/retirementsRequestedTable'),
  (module) => module.RetirementsRequestedTable,
);
const Registries = lazyComponent(
  import('./pages/registries/RegistriesTable'),
  (module) => module.RegistriesTable,
);
const RegistryDetails = lazyComponent(
  import('./pages/registries/RegistryDetails'),
  (module) => module.RegistryDetails,
);
const Tools = lazyComponent(
  import('./pages/tools/tools'),
  (module) => module.Tools,
);
const Orders = lazyComponent(
  import('./pages/orders/Orders'),
  (module) => module.Orders,
);

const AuthLogin = lazyComponent(
  import('./pages/auth/login/SystemLogin'),
  (module) => module.SystemLogin,
);
const Auth404 = lazyComponent(
  import('./pages/auth/404/System404'),
  (module) => module.System404,
);
const Auth500 = lazyComponent(
  import('./pages/auth/500/System500'),
  (module) => module.System500,
);
const DataCollectionConfigureSupplierEntitlements = lazyComponent(
  import('./pages/dataCollection/ConfigureSupplierEntitlements'),
  (module) => module.ConfigureSupplierEntitlements,
);
const DataCollectionLinkImport = lazyComponent(
  import('./pages/dataCollection/LinkImport'),
  (module) => module.LinkImport,
);
const DataCollectionSubmitRequestData = lazyComponent(
  import('./pages/dataCollection/SubmitRequestData'),
  (module) => module.SubmitRequestData,
);

const sentryCreateBrowserRouter =
  process.env.NODE_ENV === 'production'
    ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
    : createBrowserRouter;

const { formatMessage } = getIntl();

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: (
      <BaseLayout>
        <MainLayout>
          <Suspense
            fallback={
              <div className="flex w-full h-80 justify-center items-center">
                <Spin
                  indicator={<LoadingIcon className="animate-spin" />}
                  size="large"
                  delay={100}
                />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </MainLayout>
      </BaseLayout>
    ),
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'clients',
        handle: { crumb: () => formatMessage({ id: 'clients.title' }) },
        children: [
          {
            index: true,
            element: <Clients />,
          },
          {
            path: 'add',
            element: <ClientsAdd />,
          },
          {
            path: ':clientId',
            handle: { crumb: ({ params }: UIMatch) => params.clientId },
            children: [
              {
                index: true,
                element: <Client />,
              },
              {
                path: 'integrations/add',
                handle: {
                  crumb: () =>
                    formatMessage({ id: 'clients.integrations.add.title' }),
                },
                element: <ClientIntegrationEdit />,
              },
              {
                path: 'integrations/:integrationId',
                handle: {
                  crumb: ({ params }: UIMatch) =>
                    formatMessage(
                      { id: 'clients.integrations.edit.title' },
                      { id: params.integrationId },
                    ),
                },
                element: <ClientIntegrationEdit />,
              },
            ],
          },
        ],
      },
      {
        path: 'offsetting/portfolios',
        handle: { crumb: () => formatMessage({ id: 'portfolios.title' }) },
        children: [
          {
            index: true,
            element: <Portfolios />,
          },
          {
            path: ':portfolioId',
            element: <PortfolioDetails />,
            handle: { crumb: ({ params }: UIMatch) => params.portfolioId },
          },
        ],
      },
      {
        path: 'offsetting/project-developers',
        handle: {
          crumb: () => formatMessage({ id: 'projectDevelopers.title' }),
        },
        children: [
          {
            index: true,
            element: <ProjectDevelopers />,
          },
          {
            path: ':projectDeveloperId',
            element: <ProjectDevelopersDetails />,
            handle: {
              crumb: ({ params }: UIMatch) => params.projectDeveloperId,
            },
          },
        ],
      },
      {
        path: 'offsetting/projects',
        handle: { crumb: () => formatMessage({ id: 'projects.title' }) },
        children: [
          {
            index: true,
            element: <Projects />,
          },
          {
            path: ':projectId/:tabName?',
            element: <ProjectDetails />,
            handle: { crumb: ({ params }: UIMatch) => params.projectId },
          },
        ],
      },
      {
        path: 'offsetting/project-owners',
        handle: { crumb: () => formatMessage({ id: 'project-owners.title' }) },
        children: [
          {
            index: true,
            element: <ProjectOwners />,
          },
          {
            path: ':projectOwnerId',
            element: <ProjectOwnerDetails />,
            handle: { crumb: ({ params }: UIMatch) => params.projectOwnerId },
          },
        ],
      },
      {
        path: 'offsetting/project-types',
        handle: { crumb: () => formatMessage({ id: 'project-types.title' }) },
        children: [
          {
            index: true,
            element: <ProjectTypes />,
          },
          {
            path: ':projectTypeId',
            element: <ProjectTypeDetails />,
            handle: { crumb: ({ params }: UIMatch) => params.projectTypeId },
          },
        ],
      },
      {
        path: 'offsetting/project-certifications',
        handle: {
          crumb: () => formatMessage({ id: 'project-certifications.title' }),
        },
        children: [
          {
            index: true,
            element: <ProjectCertifications />,
          },
          {
            path: ':projectCertificationId',
            element: <ProjectCertificationDetails />,
            handle: {
              crumb: ({ params }: UIMatch) => params.projectCertificationId,
            },
          },
        ],
      },
      {
        path: 'offsetting/inventory',
        children: [
          {
            index: true,
            element: <Inventory />,
          },
          {
            path: 'add',
            element: <InventoryAdd />,
          },
          {
            path: 'remove',
            element: <InventoryRemove />,
          },
        ],
      },
      {
        path: 'offsetting/inventoryReconciliation',
        element: <InventoryReconciliation />,
      },
      {
        path: 'offsetting/retirements',
        children: [
          {
            index: true,
            element: <RetirementsConfirmedTable />,
          },
          {
            path: 'pending',
            element: <RetirementsRequestedTable />,
          },
        ],
      },
      {
        path: 'offsetting/registries',
        handle: { crumb: () => formatMessage({ id: 'registries.title' }) },
        children: [
          {
            index: true,
            element: <Registries />,
          },
          {
            path: ':registryId',
            element: <RegistryDetails />,
            handle: { crumb: ({ params }: UIMatch) => params.registryId },
          },
        ],
      },
      {
        path: 'data-collection/configure-supplier-entitlements',
        element: <DataCollectionConfigureSupplierEntitlements />,
      },
      {
        path: 'data-collection/link-import',
        element: <DataCollectionLinkImport />,
      },
      {
        path: 'data-collection/submit-request-data',
        element: <DataCollectionSubmitRequestData />,
      },
      {
        path: 'tools',
        element: <Tools />,
      },
      {
        path: 'orders',
        element: <Orders />,
      },
    ],
  },
  {
    path: '/auth',
    element: (
      <BaseLayout isAuthLayout={true}>
        <AuthLayout>
          <Suspense>
            <Outlet />
          </Suspense>
        </AuthLayout>
      </BaseLayout>
    ),
    children: [
      {
        path: 'login',
        element: <AuthLogin />,
      },
      {
        path: '404',
        element: <Auth404 />,
      },
      {
        path: '500',
        element: <Auth500 />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/auth/404" replace={true} />,
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
