import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Spin } from '@pledge-earth/web-components';
import {
  Heading,
  Text,
  BannerMessage,
  Separator,
  Form,
  FormSection,
  FormSectionHeader,
  Size,
  OptionListItem,
} from '@pledge-earth/product-language';
import { useQuery } from '@apollo/client';
import { useFieldArray, useWatch, type UseFormReturn } from 'react-hook-form';
import { intersectionWith } from 'lodash';

import {
  CurrencyEnum,
  GetAvailableInventoryDocument,
} from '../../../services/graphql/generated';
import { PortfolioDistribution } from '../../../components/Offsetting/PortfolioDistribution/PortfolioDistribution';
import { PortfolioSummaryBreakdown } from '../PortfolioSummaryBreakdown';
import type { FormData } from '../PortfolioEdit';
import { FormErrors } from '../../../components/ReactHookForm/FormErrors';
import { TagFieldAutocomplete } from '../../../components/Input/TagFieldAutocomplete/TagFieldAutocomplete';

import { ProjectInventoryCard } from './ProjectInventoryCard';
import { calculatePortfolioDraftSummaryFields } from './portfolioEditHelpers';
import './PortfolioEditAllocations.scss';

export function PortfolioEditAllocations({
  formId,
  formMethods,
}: {
  formId: string;
  formMethods: UseFormReturn<FormData>;
}) {
  const { formatMessage } = useIntl();
  const { control, formState } = formMethods;
  const portfolioDraft = useWatch({
    control,
    name: 'portfolio_draft',
  });
  const { replace } = useFieldArray({
    control,
    name: 'portfolio_draft.allocations',
  });

  const { data: dataInventory, loading: loadingInventory } = useQuery(
    GetAvailableInventoryDocument,
  );
  const [selectedProjectInventories, setSelectedProjectInventories] = useState<
    { inventory_id: string; assigned_weight: number }[]
  >([]);
  const [invalidProjects, setInvalidProjects] = useState<
    { project_name: string; assigned_weight: number }[]
  >([]);

  useEffect(() => {
    const projectInventories: {
      inventory_id: string;
      assigned_weight: number;
    }[] = [];
    if (!dataInventory?.available_inventory) {
      return;
    }
    portfolioDraft?.allocations?.forEach((allocation) => {
      const matchingInventory = dataInventory?.available_inventory.find(
        (inventory) =>
          inventory.project.id === allocation.project_id &&
          inventory.vintage === allocation.vintage,
      );
      if (
        matchingInventory &&
        (matchingInventory.weight_kg - matchingInventory.allocated_weight_kg) /
          1000 >=
          allocation.weight_tonnes
      ) {
        projectInventories.push({
          inventory_id: matchingInventory.id,
          assigned_weight: allocation.weight_tonnes,
        });
      } else {
        const updatedAllocations = portfolioDraft?.allocations?.filter(
          (allocationInState) => allocationInState !== allocation,
        );
        if (updatedAllocations) {
          replace(updatedAllocations);
        }

        const updatedInvalidProjects = [
          ...invalidProjects,
          {
            project_name: 'project_name',
            assigned_weight: allocation.weight_tonnes,
          },
        ];
        setInvalidProjects(updatedInvalidProjects);
      }
    });
    setSelectedProjectInventories(projectInventories ?? []);
  }, [
    dataInventory?.available_inventory,
    invalidProjects,
    setInvalidProjects,
    setSelectedProjectInventories,
    portfolioDraft,
    replace,
  ]);

  const updateAssignedWeight = useCallback(
    (project_id: string, vintage: string, weight_tonnes: number | null) => {
      const matchingAllocationInStateIndex =
        portfolioDraft.allocations?.findIndex(
          (allocation) =>
            allocation.project_id === project_id &&
            allocation.vintage === vintage,
        ) ?? -1;
      if (portfolioDraft.allocations && matchingAllocationInStateIndex >= 0) {
        const { allocations } = portfolioDraft;
        allocations[matchingAllocationInStateIndex]!.weight_tonnes = Math.floor(
          parseFloat(weight_tonnes!.toString()),
        );
        replace(allocations);
      }
    },
    [portfolioDraft, replace],
  );

  const matchInventoryAndRenderInventoryCards = useCallback(
    (selectedProjectInventory: {
      inventory_id: string;
      assigned_weight: number;
    }) => {
      const matchingInventory = dataInventory?.available_inventory?.find(
        (inventory) => inventory.id === selectedProjectInventory.inventory_id,
      );
      if (matchingInventory) {
        return (
          <ProjectInventoryCard
            inventory={matchingInventory}
            assigned_weight={selectedProjectInventory.assigned_weight}
            update_assigned_weight={updateAssignedWeight}
            key={matchingInventory.id}
          />
        );
      }
      return null;
    },
    [dataInventory?.available_inventory, updateAssignedWeight],
  );

  const renderRemovedInventoryWarning = useMemo(() => {
    const removedProjectsBulletPoints = invalidProjects.map(
      (invalidProject) => (
        <li>
          {invalidProject.project_name}: {invalidProject.assigned_weight} t
        </li>
      ),
    );
    return (
      <div>
        <FormattedMessage id="portfolio.edit.the-following-projects" />
        <ul>{removedProjectsBulletPoints}</ul>
      </div>
    );
  }, [invalidProjects]);

  const summaryFields = calculatePortfolioDraftSummaryFields(portfolioDraft);

  return (
    <Form id={formId} noValidate={true} aria-label="Edit portfolio">
      <FormErrors formState={formState} />

      <FormSection>
        <FormSectionHeader>
          <Heading>
            <FormattedMessage id="portfolio.edit.project-allocation" />
          </Heading>
        </FormSectionHeader>

        <Spin spinning={loadingInventory}>
          {invalidProjects.length > 0 && (
            <BannerMessage
              variant="critical"
              header={formatMessage({
                id: 'portfolio.edit.inventory-not-available',
              })}
              slot={null}
            >
              <Text>{renderRemovedInventoryWarning}</Text>
            </BannerMessage>
          )}

          <TagFieldAutocomplete
            label={formatMessage({
              id: 'portfolio.edit.add-projects',
            })}
            selectedKeys={selectedProjectInventories?.map(
              (inv) => inv.inventory_id,
            )}
            size={Size.Loose}
            queryDocument={GetAvailableInventoryDocument}
            queryVariables={{}}
            selectItems={(data) => data.available_inventory}
            renderTag={(key) => {
              const inventory = dataInventory?.available_inventory?.find(
                (i) => i.id === key.toString(),
              );

              return inventory
                ? `${inventory.project.name} - ${inventory.vintage}`
                : key;
            }}
            onSelectionChange={(inventoryIds) => {
              const inventories =
                inventoryIds === 'all'
                  ? (dataInventory?.available_inventory ?? [])
                  : intersectionWith(
                      dataInventory?.available_inventory,
                      Array.from(inventoryIds),
                      (o, id) => o.id === id,
                    );

              const allocations = inventories?.map((inventory) => ({
                project_id: inventory.project.id,
                project_name: inventory.project.name,
                registry_id: inventory.registry.id,
                registry: inventory.registry.name,
                vintage: inventory.vintage,
                weight_tonnes: 0,
                price_per_kg_in_gbp: inventory.price_per_kg_in_gbp,
                avoidance: inventory.project.avoidance,
                removal: inventory.project.removal,
                registry_fee_per_tonne:
                  inventory.project.registry
                    ?.registry_retirement_fee_per_tonne ?? 0,
              }));

              replace(allocations);
            }}
          >
            {(item) => (
              <OptionListItem
                id={item.id}
              >{`${item.project.name} - ${item.vintage}`}</OptionListItem>
            )}
          </TagFieldAutocomplete>
          {selectedProjectInventories.map((selectedProjectInventory) =>
            matchInventoryAndRenderInventoryCards(selectedProjectInventory),
          )}
        </Spin>
      </FormSection>

      <FormSection>
        <FormSectionHeader>
          <Heading>
            <FormattedMessage id="portfolio.edit.summary" />
          </Heading>
        </FormSectionHeader>

        <div className="PortfolioEditAllocations__price-summary">
          <div className="PortfolioEditAllocations__price-summary__distribution">
            <PortfolioDistribution
              removal={summaryFields.removal}
              avoidance={summaryFields.avoidance}
              hideDescription={true}
            />
          </div>
          <Separator className="my-6" />
          <div className="PortfolioEditAllocations__price-summary__breakdown">
            <PortfolioSummaryBreakdown
              currency={CurrencyEnum.Gbp}
              pricePerTonne={summaryFields.pricePerTonne}
              allocatedTonnes={summaryFields.allocatedTonnes}
              totalProjects={portfolioDraft.allocations?.length ?? 0}
            />
          </div>
        </div>
      </FormSection>
    </Form>
  );
}
