import {
  ActionMenu,
  Avatar,
  ChevronDownIcon,
  IconButton,
  MenuItem,
} from '@pledge-earth/product-language';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { logout as logoutAction } from '../../../store/user/reducers';

export function UserMenu() {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);

  return (
    <ActionMenu
      triggerElement={
        <IconButton label="User menu" variant="plain" className="w-auto">
          <Avatar>{user.name.charAt(0)?.toUpperCase()}</Avatar>
          <ChevronDownIcon />
        </IconButton>
      }
    >
      <MenuItem key="logout" onAction={() => dispatch(logoutAction())}>
        <FormattedMessage id="topBar.profileMenu.logout" />
      </MenuItem>
    </ActionMenu>
  );
}
