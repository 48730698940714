import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import type { Size } from '@pledge-earth/product-language';
import { Tag } from '@pledge-earth/product-language';

import type { MessageIdSuffix } from '../../../locales/types';

export function PortfolioTaxonomyTag({
  removalPercentage,
  className,
  ...props
}: {
  removalPercentage: number;
  className?: string;
  size?: Size.Compact | Size.Default;
}) {
  let avoidanceRemovalTextId: MessageIdSuffix<'offsetting.taxonomy'>;

  if (removalPercentage === 0) {
    avoidanceRemovalTextId = '100%Avoidance';
  } else if (removalPercentage > 0 && removalPercentage < 20) {
    avoidanceRemovalTextId = 'mostlyAvoidance';
  } else if (removalPercentage >= 20 && removalPercentage < 40) {
    avoidanceRemovalTextId = 'moreAvoidance';
  } else if (removalPercentage >= 40 && removalPercentage < 60) {
    avoidanceRemovalTextId = 'mixed';
  } else if (removalPercentage >= 60 && removalPercentage < 80) {
    avoidanceRemovalTextId = 'moreRemovals';
  } else if (removalPercentage >= 80 && removalPercentage < 100) {
    avoidanceRemovalTextId = 'mostlyRemovals';
  } else if (removalPercentage === 100) {
    avoidanceRemovalTextId = '100%Removals';
  } else {
    throw new Error(
      `Could not map portfolio removal percentage ${removalPercentage} to removals tag text`,
    );
  }

  return (
    <Tag
      {...props}
      variant="accent-cornflower"
      className={classNames('mr-[0.188rem]', className)}
    >
      <FormattedMessage id={`offsetting.taxonomy.${avoidanceRemovalTextId}`} />
    </Tag>
  );
}
