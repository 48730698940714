import {
  Button,
  ButtonGroup,
  Heading,
  TickCircleFilledIcon,
} from '@pledge-earth/product-language';
import { FormattedMessage } from 'react-intl';

export function EntityAdded({
  closeModal,
  entityAddedTitle,
  actionButtonCTAText,
  actionButtonCTA,
}: {
  closeModal: () => void;
  entityAddedTitle: string;
  actionButtonCTAText: string;
  actionButtonCTA: () => void;
}) {
  return (
    <div className="flex flex-col gap-4 items-center py-8">
      <TickCircleFilledIcon className="w-10 h-10 text-icon-accent" />
      <Heading level="h4">{entityAddedTitle}</Heading>

      <ButtonGroup>
        <Button onPress={actionButtonCTA}>{actionButtonCTAText}</Button>
        <Button variant="primary" onPress={closeModal}>
          <FormattedMessage id="entity-added.done" />
        </Button>
      </ButtonGroup>
    </div>
  );
}
