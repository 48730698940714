import { get, omit } from 'lodash';
import { useCallback, useId, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Spin } from '@pledge-earth/web-components';
import {
  BannerMessage,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  FormSection,
  OptionListItem,
  OverlayCloseButton,
  Size,
} from '@pledge-earth/product-language';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';

import type {
  GetProjectTypeQuery,
  ProjectType,
} from '../../services/graphql/generated';
import {
  GetProjectTypeCategoriesDocument,
  UpsertProjectTypeDocument,
} from '../../services/graphql/generated';
import { EntityAdded } from '../../components/AddEntity/EntityAdded/EntityAdded';
import { showSuccessToast } from '../../utils/toast';
import { defaultUseFormProps } from '../../components/ReactHookForm/defaultUseFormProps';
import { useRootFormError } from '../../components/ReactHookForm/useRootFormError';
import { FormErrors } from '../../components/ReactHookForm/FormErrors';
import { TextFieldControlled } from '../../components/ReactHookForm/TextFieldControlled';
import { SelectFieldControlled } from '../../components/ReactHookForm/SelectFieldControlled';

interface FormData {
  name: string;
  category: string;
}

export function ProjectTypeEdit({
  closeModal,
  onSaved,
  projectType = {} as ProjectType,
}: {
  closeModal: () => void;
  projectType?: GetProjectTypeQuery['project_type'];
  onSaved?: () => void;
}) {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const formId = useId();
  const [showSuccess, setShowSuccess] = useState(false);
  const [createdProjectTypeId, setCreatedProjectTypeId] = useState<string>();

  const [upsertProjectTypeMutation, { loading, error }] = useMutation(
    UpsertProjectTypeDocument,
  );

  const {
    data: dataProjectTypeCategories,
    loading: loadingProjectTypeCategories,
  } = useQuery(GetProjectTypeCategoriesDocument);

  const handleFormFinished = useCallback(
    async (values: any) => {
      const { name, category } = values;

      const data: any = omit(
        {
          ...projectType,
          name,
          category,
        },
        ['updated_date', '__typename'],
      );

      const result = await upsertProjectTypeMutation({
        variables: {
          data,
        },
      });

      const projectTypeId = result.data?.upsert_project_type?.id;

      if (onSaved) {
        showSuccessToast({
          description: 'Project type has been updated',
        });

        onSaved();
      } else {
        setCreatedProjectTypeId(projectTypeId);
        setShowSuccess(true);
      }
    },
    [onSaved, projectType, upsertProjectTypeMutation],
  );

  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const redirectToProjectTypeDetailsPage = useCallback(() => {
    if (createdProjectTypeId) {
      navigate(`/offsetting/project-types/${createdProjectTypeId}`);
    }
    closeModal();
  }, [createdProjectTypeId, closeModal, navigate]);

  const { control, formState, handleSubmit } = useForm<FormData>({
    ...defaultUseFormProps,
    errors: useRootFormError(error),
    defaultValues: {
      name: projectType?.name ?? '',
      category: projectType?.category ?? '',
    },
  });

  return (
    <Dialog>
      <DialogHeader>
        <DialogTitle id="project-type-heading">
          {get(projectType, 'id') ? (
            <FormattedMessage id="project-type.edit.title" />
          ) : (
            <FormattedMessage id="project-type.add.title" />
          )}
        </DialogTitle>
        <OverlayCloseButton label={formatMessage({ id: 'close' })} />
      </DialogHeader>

      <DialogBody>
        {error ? (
          <BannerMessage variant="critical">
            <FormattedMessage id="project-type.edit.error" />
          </BannerMessage>
        ) : null}

        {showSuccess ? (
          <EntityAdded
            closeModal={handleClose}
            entityAddedTitle="Project Type Added"
            actionButtonCTAText="View Project Type"
            actionButtonCTA={redirectToProjectTypeDetailsPage}
          />
        ) : null}

        <Spin spinning={loadingProjectTypeCategories}>
          {!showSuccess && (
            <Form
              id={formId}
              aria-labelledby="project-type-heading"
              noValidate={true}
              onSubmit={handleSubmit(handleFormFinished)}
            >
              <FormErrors formState={formState} />

              <FormSection>
                <TextFieldControlled
                  control={control}
                  name="name"
                  size={Size.Loose}
                  label={formatMessage({ id: 'project-type.edit.name' })}
                  rules={{
                    required: formatMessage({
                      id: 'project-type.edit.name.required',
                    }),
                  }}
                />

                <SelectFieldControlled
                  control={control}
                  name="category"
                  size={Size.Loose}
                  label={formatMessage({ id: 'project-type.edit.category' })}
                  rules={{
                    required: formatMessage({
                      id: 'project-type.edit.category.required',
                    }),
                  }}
                  items={dataProjectTypeCategories?.project_type_categories}
                >
                  {({ category }) => (
                    <OptionListItem id={category}>{category}</OptionListItem>
                  )}
                </SelectFieldControlled>
              </FormSection>
            </Form>
          )}
        </Spin>
      </DialogBody>

      {!showSuccess && (
        <DialogFooter>
          <Button onPress={closeModal}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            variant="primary"
            type="submit"
            form={formId}
            isLoading={loading}
          >
            <FormattedMessage id="save" />
          </Button>
        </DialogFooter>
      )}
    </Dialog>
  );
}
