import { FormattedMessage } from 'react-intl';

import { ExternalLink } from '../Link/ExternalLink';

export function Footer() {
  return (
    <div className="flex items-center justify-center gap-4 text-center">
      <ExternalLink
        variant="monochrome"
        href="https://www.pledge.io/privacy"
        noExternalIcon={true}
      >
        <FormattedMessage id="privacy_policy" />
      </ExternalLink>
      <span>&bull;</span>
      <ExternalLink
        variant="monochrome"
        href="https://www.pledge.io/terms"
        noExternalIcon={true}
      >
        <FormattedMessage id="terms" />
      </ExternalLink>
    </div>
  );
}
