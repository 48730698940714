import { ScrollRestoration, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';

import { useAppSelector } from '../store/hooks';

function BaseLayout({
  children,
  isAuthLayout,
}: PropsWithChildren<{ isAuthLayout?: boolean }>) {
  const user = useAppSelector((state) => state.user);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  let previousPath = '';
  const currentPath = pathname + search;
  if (currentPath !== previousPath) {
    window.scrollTo(0, 0);
  }
  setTimeout(() => {
    previousPath = currentPath;
  }, 300);

  const isUserAuthorized = user.authorized;
  const isUserLoading = user.loading;

  useEffect(() => {
    if (isUserLoading) {
      return;
    }

    let url = '/auth/login';
    if (
      pathname === '/' ||
      pathname === '/auth/login' ||
      pathname.startsWith('/error_description')
    ) {
      url = '/auth/login';
    } else {
      url = `/auth/login?redirectTo=${currentPath}`;
    }

    // redirect to login page if we're not authorized and not on login page
    if (!isAuthLayout && !isUserAuthorized) {
      navigate(url, { replace: true });
    } else if (pathname === '/') {
      navigate('/dashboard', { replace: true });
    }
  }, [
    navigate,
    isUserAuthorized,
    isUserLoading,
    isAuthLayout,
    currentPath,
    pathname,
  ]);

  return (
    <>
      <Helmet titleTemplate="Pledge | %s" title="Pledge" />
      <ScrollRestoration />
      {children}
    </>
  );
}

export { BaseLayout };
